<template>
  <div class="row">
    <div class="col-sm-12 col-lg-4 col-md-6 linkContainer pointer" v-for="(item, index) of propCareerLinkObj" :key="index" @click="openMedia(item.carr_pba_meta.url)">
      <div class="competionBackImg avtarText">
        <img :src="`/Career_pba/${item.carr_pba_type}${index % CAREER_IMG_COUNT}.png`" class="w-100" style="height: 100%;"/>
      </div>
      <h5 v-if="item.carr_pba_meta" class="metaTitle threeLineOnly text-center mt-3">
        {{item.carr_pba_meta.title}}
      </h5>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .comContainer
  {
    min-height: 197px;
    overflow: hidden;
    margin-bottom: 28px;
    .titlePt {
      padding-top: 30px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .fourLineOnly:hover {
    overflow: visible !important;
    -webkit-line-clamp: unset;
  }
/*   .tooltipContainer .tooltipText {
    visibility: hidden;
    position: absolute;
  }
  .tooltipContainer:hover .tooltipText {
    visibility: visible;
  } */
  .tooltipContainer{
    margin: auto;
    .titlePt{
      padding:10px 0px
    }
  }
  .competionBackImg{
    max-width: 260px;
    height: 260px;
    object-fit: contain;
    margin:auto;
    position: relative;
    margin-bottom: 10px;
    }
    .avtarText {
    background-color: #EEEEEE;
    text-align: center;
    .titleSpan {
      margin: auto;
      font-size: 50px;
      top: calc(50% - 50px);
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }

  .metaTitle{
    text-align: center;
    max-width: 236px;
    margin: auto !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 55px;
    margin-bottom: 20px !important;
  }
</style>
<script>
export default {
  name: "TabCard",
  props: {
    propCareerLinkObj: {
      type: []
    },
    propParent: {
      type: String
    }
  },
  data () {
    return {
      CAREER_IMG_COUNT: 5
    }
  },
  mounted () {
  },
  methods: {
    /**
     * setAltImg
     */
    setAltImg (event) {
      event.target.src = "https://gide-assets.s3.amazonaws.com/no_image_available.jpeg"
    },
    /**
     * openMedia
     */
    openMedia (url) {
      window.open(url, "_blank")
    }
  }
}
</script>
