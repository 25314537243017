<template>
  <div class="row">
    <div class="col-md-12">
      <div class="iq-card">
        <div class="iq-card-body">
          <h4>
            About
          </h4>
          <hr />
          <!-- <p class="mb-2">{{propCareerObj.carr_field}}</p> -->
          <p class="mb-2 vhtmlTag vueHtmlParent" v-html='propCareerObj.carr_desc'>
          </p>
          <br>
          <h4>
            Career  Roles and responsibility
          </h4>
          <hr />
          <p class="mb-2 vhtmlTag vueHtmlParent" v-html='propCareerObj.carr_role_responsibility'>
          </p>
        </div>
      </div>
      <div class="iq-card">
        <div class="iq-card-body">
          <h4>
            Hiring  companies
          </h4>
          <hr />
          <p class="mb-2 vhtmlTag vueHtmlParent" v-html='propCareerObj.carr_hiring_org'>
          </p>
          <br>
          <h4>
            Career Fact Stories
          </h4>
          <hr />
          <p class="mb-2 vhtmlTag vueHtmlParent" v-html='propCareerObj.carr_facts_stories'>
          </p>
          <br>
          <h4>
            Career Myth Buster
          </h4>
          <hr />
          <p class="mb-2 vhtmlTag vueHtmlParent" v-html='propCareerObj.carr_myth_buster'>
          </p>
          <h4>
            Career Journey
          </h4>
          <hr />
          <p class="mb-2 vhtmlTag vueHtmlParent" v-html='propCareerObj.carr_journey'>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  props: {
    propCareerObj: {
      type: Object,
      default: function () {
        return {
          carr_field: "",
          carr_desc: "",
          carr_facts_stories: "",
          carr_journey: "",
          carr_myth_buster: "",
          carr_opp: "",
          carr_role_responsibility: "",
          carr_cat_name: ""
        }
      }
    }
  }
}
</script>
