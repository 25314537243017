/*eslint-disable */
// import CareerOpportunityModel from '../../Model/CareerOpportunity'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let CareerOpportunities = {
  /**
   * careerOpportunityList
   */
  async careerOpportunityList (context, careerOpportunityObj = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();

      for (let key in careerOpportunityObj) {
        post_data.append(key, careerOpportunityObj[key]);
      }
      return await request.curl(context, "careerOpportunity_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at careerOpportunityList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * careerOpportunityView
   */
  async careerOpportunityView (context, careerOpportunityId) {
    try {
      
      let post_data = new FormData();
      post_data.append('carr_opp_id', careerOpportunityId);
      return await request.curl(context, "careerOpportunity_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at careerOpportunityList() and Exception:",err.message)
    }
    
  },

  /**
   * careerOpportunityAdd
   */
  async careerOpportunityAdd (context, careerOpportunityObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerOpportunityObj) {
      post_data.append(key, careerOpportunityObj[key]);
    }

    return await request.curl(context, "careerOpportunity_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerOpportunityAdd() and Exception:',err.message)
    }
  },

  /**
   * careerOpportunityEdit
   */
  async careerOpportunityEdit (context, careerOpportunityObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerOpportunityObj) {
      post_data.append(key, careerOpportunityObj[key]);
    }

    return await request.curl(context, "careerOpportunity_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerOpportunityEdit() and Exception:',err.message)
    }
  },

  /**
   * careerOpportunityDelete
   */
  async careerOpportunityDelete (context, careerOpportunityId) {
    try{
    let post_data = new FormData();
    
    post_data.append('carr_opp_id', careerOpportunityId);

    return await request.curl(context, "careerOpportunity_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerOpportunityDelete() and Exception:',err.message)
    }
  }
}

export {
  CareerOpportunities
}
